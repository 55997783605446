<template lang="html">
  <section class="view-offer">
    <div class="cabecera-oferta container">
      <h1>{{ $t("offers." + slug + ".title") }}</h1>
      <a target="_blank" :href="$t('offers.' + slug + '.inscripcion')">
        <p class="btn-bases -blue">SOLICITAR</p>
      </a>
    </div>

    <div class="container">
      <div class="descripcion">
        <div v-html="$t('offers.' + slug + '.description')"></div>
        <hr />
        <div class="container-links">
          <a
            class="btn-bases -blue"
            target="_blank"
            :href="$t('offers.' + slug + '.inscripcion')"
          >
            SOLICITAR
          </a>
          <a
            class="btn-bases -blueDark"
            target="_blank"
            download=""
            :href="$t('offers.' + slug + '.bases')"
          >
            BASES Y MATERIALES
          </a>
        </div>
      </div>

      <!-- <div class="descripcion">
        <h2>Requisitos</h2>
        <p>{{$t('offers.'+slug+'.requisitos')}} </p>
        
      </div> -->
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'view-offer',

  props: ['slug'],
  mounted() {

  },
  data() {
    return {
      activada: false,
    }
  },
  methods: {

  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
.view-offer {
}
</style>
